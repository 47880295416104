import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  linkActiveClass: "link-active",
  linkExactActiveClass: "link-exact-active text-primary",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
}