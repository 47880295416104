import { default as _91_46_46_46all_9332md4N7Z4HMeta } from "/opt/buildhome/repo/pages/[...all].vue?macro=true";
import { default as addressZve8IjYNeeMeta } from "/opt/buildhome/repo/pages/account/address.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as order7ms7PO2lOOMeta } from "/opt/buildhome/repo/pages/account/order.vue?macro=true";
import { default as profilerpPZ78QyoXMeta } from "/opt/buildhome/repo/pages/account/profile.vue?macro=true";
import { default as recoveroLYFMV3dvwMeta } from "/opt/buildhome/repo/pages/account/recover.vue?macro=true";
import { default as cartFQ1a8u7wfYMeta } from "/opt/buildhome/repo/pages/checkout/cart.vue?macro=true";
import { default as indexQrPIrenMTVMeta } from "/opt/buildhome/repo/pages/checkout/index.vue?macro=true";
import { default as indexQ8m2dnUYAgMeta } from "/opt/buildhome/repo/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paid8jGiOi7eNuMeta } from "/opt/buildhome/repo/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaidn1vWle8oLeMeta } from "/opt/buildhome/repo/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as newsletter_45subscribefJTtC9b8woMeta } from "/opt/buildhome/repo/pages/newsletter-subscribe.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as searchW2JMgpGXtYMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as wishlistlzSU18FuZJMeta } from "/opt/buildhome/repo/pages/wishlist.vue?macro=true";
export default [
  {
    name: "all___da-DK",
    path: "/:all(.*)*",
    meta: _91_46_46_46all_9332md4N7Z4HMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "all___en-GB",
    path: "/en-GB/:all(.*)*",
    meta: _91_46_46_46all_9332md4N7Z4HMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "all___de-DE",
    path: "/de-DE/:all(.*)*",
    meta: _91_46_46_46all_9332md4N7Z4HMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "account-address___da-DK",
    path: "/account/address",
    meta: addressZve8IjYNeeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account-address___en-GB",
    path: "/en-GB/account/address",
    meta: addressZve8IjYNeeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account-address___de-DE",
    path: "/de-DE/account/address",
    meta: addressZve8IjYNeeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: "account___da-DK",
    path: "/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en-GB",
    path: "/en-GB/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de-DE",
    path: "/de-DE/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order___da-DK",
    path: "/account/order",
    meta: order7ms7PO2lOOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: "account-order___en-GB",
    path: "/en-GB/account/order",
    meta: order7ms7PO2lOOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: "account-order___de-DE",
    path: "/de-DE/account/order",
    meta: order7ms7PO2lOOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___da-DK",
    path: "/account/profile",
    meta: profilerpPZ78QyoXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___en-GB",
    path: "/en-GB/account/profile",
    meta: profilerpPZ78QyoXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___de-DE",
    path: "/de-DE/account/profile",
    meta: profilerpPZ78QyoXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-recover___da-DK",
    path: "/account/recover",
    meta: recoveroLYFMV3dvwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: "account-recover___en-GB",
    path: "/en-GB/account/recover",
    meta: recoveroLYFMV3dvwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: "account-recover___de-DE",
    path: "/de-DE/account/recover",
    meta: recoveroLYFMV3dvwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: "checkout-cart___da-DK",
    path: "/checkout/cart",
    meta: cartFQ1a8u7wfYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-cart___en-GB",
    path: "/en-GB/checkout/cart",
    meta: cartFQ1a8u7wfYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-cart___de-DE",
    path: "/de-DE/checkout/cart",
    meta: cartFQ1a8u7wfYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout___da-DK",
    path: "/checkout",
    meta: indexQrPIrenMTVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en-GB",
    path: "/en-GB/checkout",
    meta: indexQrPIrenMTVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___de-DE",
    path: "/de-DE/checkout",
    meta: indexQrPIrenMTVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id___da-DK",
    path: "/checkout/success/:id()",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id___en-GB",
    path: "/en-GB/checkout/success/:id()",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id___de-DE",
    path: "/de-DE/checkout/success/:id()",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id-paid___da-DK",
    path: "/checkout/success/:id()/paid",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/paid.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id-paid___en-GB",
    path: "/en-GB/checkout/success/:id()/paid",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/paid.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id-paid___de-DE",
    path: "/de-DE/checkout/success/:id()/paid",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/paid.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id-unpaid___da-DK",
    path: "/checkout/success/:id()/unpaid",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/unpaid.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id-unpaid___en-GB",
    path: "/en-GB/checkout/success/:id()/unpaid",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/unpaid.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-id-unpaid___de-DE",
    path: "/de-DE/checkout/success/:id()/unpaid",
    component: () => import("/opt/buildhome/repo/pages/checkout/success/[id]/unpaid.vue").then(m => m.default || m)
  },
  {
    name: "login___da-DK",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en-GB",
    path: "/en-GB/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___de-DE",
    path: "/de-DE/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-subscribe___da-DK",
    path: "/newsletter-subscribe",
    meta: newsletter_45subscribefJTtC9b8woMeta || {},
    component: () => import("/opt/buildhome/repo/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-subscribe___en-GB",
    path: "/en-GB/newsletter-subscribe",
    meta: newsletter_45subscribefJTtC9b8woMeta || {},
    component: () => import("/opt/buildhome/repo/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: "newsletter-subscribe___de-DE",
    path: "/de-DE/newsletter-subscribe",
    meta: newsletter_45subscribefJTtC9b8woMeta || {},
    component: () => import("/opt/buildhome/repo/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: "register___da-DK",
    path: "/register",
    meta: registerynsVf0yvMzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en-GB",
    path: "/en-GB/register",
    meta: registerynsVf0yvMzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___de-DE",
    path: "/de-DE/register",
    meta: registerynsVf0yvMzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___da-DK",
    path: "/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en-GB",
    path: "/en-GB/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___de-DE",
    path: "/de-DE/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "search___da-DK",
    path: "/search",
    meta: searchW2JMgpGXtYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en-GB",
    path: "/en-GB/search",
    meta: searchW2JMgpGXtYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___de-DE",
    path: "/de-DE/search",
    meta: searchW2JMgpGXtYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___da-DK",
    path: "/wishlist",
    meta: wishlistlzSU18FuZJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___en-GB",
    path: "/en-GB/wishlist",
    meta: wishlistlzSU18FuZJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "wishlist___de-DE",
    path: "/de-DE/wishlist",
    meta: wishlistlzSU18FuZJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/wishlist.vue").then(m => m.default || m)
  }
]