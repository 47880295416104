import revive_payload_client_ojrl7bilxw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GNw8WtXrqg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4eF4iwGEMP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UTaIxPDr0P from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_64RuKyINsM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_tCrekkvedE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9RxiC0tlWw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_01PoNz7nCc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.7.0_rollup@4.21.0_typescript@5.5.3_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_A9cnsRhW7O from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_rollup@4.21.0_vite@5.4.2_vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_XQml0hbkNw from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_rollup@4.21.0_vite@5.4.2_vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_EU3sdHzsNU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_rollup@4.21.0_vite@5.4.2_vue@3.4.31/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xkGf2LM3JS from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_rollup@4.21.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_B4HjEAc8ax from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.4.7_rollup@4.21.0_vite@5.4.2_vue@3.4.31/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import i18n_6IBvgWIkH8 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.21.0_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_Giungextjh from "/opt/buildhome/repo/node_modules/.pnpm/@shopware-pwa+nuxt3-module@0.0.0-canary-20240821074025_rollup@4.21.0_vue@3.4.31/node_modules/@shopware-pwa/nuxt3-module/plugin.ts";
import sentry_client_r41Qi0E5KB from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.26.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+instru_masljcax6w6shfv7u6q7uvncvm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import ssr_plugin_89do9j53Mj from "/opt/buildhome/repo/node_modules/.pnpm/@vueuse+nuxt@10.11.0_nuxt@3.12.4_rollup@4.21.0_vue@3.4.31/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
export default [
  revive_payload_client_ojrl7bilxw,
  unhead_GNw8WtXrqg,
  router_4eF4iwGEMP,
  payload_client_UTaIxPDr0P,
  navigation_repaint_client_64RuKyINsM,
  check_outdated_build_client_tCrekkvedE,
  chunk_reload_client_9RxiC0tlWw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_01PoNz7nCc,
  slideovers_A9cnsRhW7O,
  modals_XQml0hbkNw,
  colors_EU3sdHzsNU,
  plugin_client_xkGf2LM3JS,
  plugin_B4HjEAc8ax,
  i18n_6IBvgWIkH8,
  plugin_Giungextjh,
  sentry_client_r41Qi0E5KB,
  sentry_client_config_o34nk2sJbg,
  ssr_plugin_89do9j53Mj
]