
// @ts-nocheck
import locale__opt_buildhome_repo_i18n_src_langs_da_DK_ts from "../i18n/src/langs/da-DK.ts?hash=50359ee1&locale=da-DK";
import locale__opt_buildhome_repo_i18n_src_langs_en_GB_ts from "../i18n/src/langs/en-GB.ts?hash=9a3a5a3e&locale=en-GB";
import locale__opt_buildhome_repo_i18n_src_langs_de_DE_ts from "../i18n/src/langs/de-DE.ts?hash=35be4140&locale=de-DE";


export const localeCodes =  [
  "da-DK",
  "en-GB",
  "de-DE"
]

export const localeLoaders = {
  "da-DK": [{ key: "../i18n/src/langs/da-DK.ts", load: () => Promise.resolve(locale__opt_buildhome_repo_i18n_src_langs_da_DK_ts), cache: false }],
  "en-GB": [{ key: "../i18n/src/langs/en-GB.ts", load: () => Promise.resolve(locale__opt_buildhome_repo_i18n_src_langs_en_GB_ts), cache: false }],
  "de-DE": [{ key: "../i18n/src/langs/de-DE.ts", load: () => Promise.resolve(locale__opt_buildhome_repo_i18n_src_langs_de_DE_ts), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n/config.ts?hash=4caee762&config=1" /* webpackChunkName: "__i18n_config_4caee762" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/config",
  "locales": [
    {
      "code": "da-DK",
      "iso": "da-DK",
      "localeId": "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
      "files": [
        "i18n/src/langs/da-DK.ts"
      ]
    },
    {
      "code": "en-GB",
      "iso": "en-GB",
      "localeId": "01900cd6fae6726b934a666f981223d3",
      "files": [
        "i18n/src/langs/en-GB.ts"
      ]
    },
    {
      "code": "de-DE",
      "iso": "de-DE",
      "localeId": "01900cb1f3fc70539bddaf5d90028e77",
      "files": [
        "i18n/src/langs/de-DE.ts"
      ]
    }
  ],
  "defaultLocale": "da-DK",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "./i18n/src/langs/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "da-DK",
    "iso": "da-DK",
    "localeId": "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
    "files": [
      {
        "path": "i18n/src/langs/da-DK.ts"
      }
    ]
  },
  {
    "code": "en-GB",
    "iso": "en-GB",
    "localeId": "01900cd6fae6726b934a666f981223d3",
    "files": [
      {
        "path": "i18n/src/langs/en-GB.ts"
      }
    ]
  },
  {
    "code": "de-DE",
    "iso": "de-DE",
    "localeId": "01900cb1f3fc70539bddaf5d90028e77",
    "files": [
      {
        "path": "i18n/src/langs/de-DE.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
